import { useRouter } from "next/dist/client/router";
import { useEffect } from "react";
import { setOtherSelector } from "../state/peer";
import { useStore } from "../state/store";

export default function RoomId() {
  const router = useRouter();
  const setOther = useStore(setOtherSelector);

  useEffect(() => {
    const { roomId } = router.query;
    setOther({ id: roomId as string, name: "" });
    router.push("/");
  }, [router, setOther]);

  return <></>;
}
